body, html {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo {
  height: 250px;
  pointer-events: none;
  max-width: 100%;
  margin: 10px 20px;
}

.side-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  background-color: lightgray;
}

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  align-items: center;
  overflow-y: scroll;
  background: rgba(0, 151, 178, .75);
}


@media (max-width: 1200px) {

  .side-banner {
    height: 10%;
    width: 100%;
  }

  .content {
    height: 80%;
    width: 100%;
  }

  .container {
    flex-direction: column;
    justify-content: space-between;
  }

}

.header {
  width: 100%;
  background-color: #FFFDD0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 10% 0 2% 0;
  background: #FFFDD0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(92, 92, 92);
}


.image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  box-sizing: border-box;
  gap: 20px;
}

.form-view {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.adblock-banner {
  width: 100%;
  height: 400px;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.adblock-title {
  font-size: 24px;
  color: #d9534f;
}

.adblock-text {
  font-size: 14px;
  color: #555;
  text-align: left;
}

.slider {
  display: flex;
  flex: 1;
  align-items: center;
  width: 90%;
  padding: 0px 0px 10px 0px;
}

.slider-title {
  color: #006172;
  font-size: 20px;
  /* flex: 0.66; */
}

.slider-length {
  width: 90% !important;
  padding: 0px 20px 0px 0px;
}

.slider-number {
  color: #0097B2;
  font-size: 25px;
  margin-left: 220px;
  /* flex: 0.33; */
}

.preview-image {
  flex: 1;
}

@media (max-width: 1200px) {
  .form-view {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .slider-length {
    width: 100% !important;
  }

  .preview-image {
    flex: 1;
    padding-top:  30px;
  }

  .image-container {
    flex-direction: column;
    align-items: stretch;
  }
}

/* Labels */
label {
  font-weight: bold;
  margin-top: 15px;
}

/* Button */
button.btn {
  margin-top: auto;
  background-color: #0097B2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: '200px'
}

button.btn:hover {
  background-color:	#006172;
}

button.btn-back {
  margin-top: auto;
  background-color: #FFFDD0;
  color: #545454;
  border: none;
  margin: 20px 0px 0px 0px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: '200px'
}

button.btn-back:hover {
  background-color:	#FFFDD0;
}

button.btn-alternate {
  margin-top: auto;
  background-color: #FFFDD0;
  color: #545454;
  border: none;
  margin: 0px 0px 20px 20px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: '200px'
}

button.btn-alternate:hover {
  background-color:	#FFFDD0;
}

.overlay {
  position: fixed;
  top: 0;
  width: 72%;
  height: 100%;
  background: rgba(0,0,0,0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .overlay {
    top: 10%;
    height: 80%;
    width: 100%;
    flex-direction: column;
  }
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  box-sizing: border-box;
  overflow: hidden;
}

.loading-icon {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #0097B2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hidden-file-input {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  z-index: -1;
}

.file-name {
  color: #006172;
  margin-left: 20px;
}

/********** Range Input Styles **********/
/* Form inputs and ranges */
input[type="file"],
input[type="range"] {
  width: 100%;
  margin: 10px 0;
}

/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 15rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #F0F47E;
  border-radius: 0.5rem;
  height: 0.5rem;  
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -12px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: #0097B2;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%; /* Makes the thumb circular */
  margin-top: -0.5rem; /* Adjust this value to center the thumb on the track */
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  /* background-color: #3498db; */
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #0097B2;
  height: 1.5rem; /* Equal to width for a circle */
  width: 1.5rem; /* Equal to height for a circle */
  border-radius: 50%; /* Makes the thumb circular */
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #0097B2;
  outline: 3px solid #0097B2;
  outline-offset: 0.125rem; 
}

.paypal-buttons-container {
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 400px; /* Adjust width as needed */
  margin: 0 auto 20px auto;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(92, 92, 92);
  background: #FFFDD0;
}

.paypal-buttons-container .message {
  font-size: 18px;
  margin-bottom: 10px;
}

.paypal-buttons-container .amount {
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.paypal-buttons-container .paypal-buttons {
  margin-top: 20px;
}

/* src/HelpButton.css */
.help-button-container {
  position: relative;
  display: inline-block;
}

.help-button {
  background-color: #0097B2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 30%;
}

.tooltip {
  position: absolute;
  top: -10px; /* Adjust as needed to position above the button */
  transform: translate(5%, -100%); /* Adjust to align top right */
  background-color: #FFFDD0;
  color: #0097B2;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
  border: 2px solid #0097B2; /* Added colored border */
}

.help-button:hover {
  background-color:	#006172;
}
